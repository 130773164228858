







import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../_abstract'

import { LabelsModule } from '../Labels.contracts'

/**
 * Container component for the `LabelsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<LabelsModuleUi>({
  name: 'LabelsModuleUi',
  components: {
    LabelsModuleUiPresentation: () => import(
      /* webpackChunkName: "modules/Labels" */
      './Labels.ui.presentation.vue'
    )
  }
})
export class LabelsModuleUi extends AbstractModuleUi<LabelsModule> {}

export default LabelsModuleUi
