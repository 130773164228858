












import { BootstrapBadge, BootstrapTheme } from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Alignmentable } from '../../../../extensions'
import { HorizontalAlignment } from '../../../../contracts'

import { AbstractModuleUiPresentation } from '../../_abstract'

import { LabelsModuleContent } from '../Labels.contracts'

/**
 * Presentational component for the `LabelsModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<LabelsModuleUiPresentation>({
  name: 'LabelsModuleUiPresentation',
  components: { BootstrapBadge }
})
export class LabelsModuleUiPresentation extends Mixins<
  AbstractModuleUiPresentation, Alignmentable
>(AbstractModuleUiPresentation, Alignmentable) {
  /**
   * @see LabelsModuleContent.labels
   */
  @Prop({ type: Array, required: true })
  public readonly labels!: LabelsModuleContent['labels']

  public readonly BootstrapTheme = BootstrapTheme

  /**
   * @see Alignmentable.alignmentClassNameRegistry
   */
  public readonly alignmentClassNameRegistry = {
    base: {
      [HorizontalAlignment.Center]: 'justify-content-center',
      [HorizontalAlignment.Justify]: 'justify-content-between',
      [HorizontalAlignment.Left]: 'justify-content-start',
      [HorizontalAlignment.Right]: 'justify-content-end'
    },
    desktop: {
      [HorizontalAlignment.Center]: 'justify-content-lg-center',
      [HorizontalAlignment.Justify]: 'justify-content-lg-between',
      [HorizontalAlignment.Left]: 'justify-content-lg-start',
      [HorizontalAlignment.Right]: 'justify-content-lg-end'
    }
  }

  /**
   * Determines whether the component has been provided with the correct `labels` @Prop.
   */
  public get hasLabels (): boolean {
    return typeof this.labels !== 'undefined' &&
      Array.isArray(this.labels) &&
      this.labels.length > 0
  }
}

export default LabelsModuleUiPresentation
